<template>
  <div class="home">
    <h1>Rent a boat</h1>
    <p>{{ $t("strings.rentals_title") }}</p>
    <p class="smaller">{{ $t("strings.rentals_subtitle") }}</p>
    <div class="boats excursions-rentals">
      <div class="boat">
        <img
          src="@/assets/images/boat/yamaha/yam-550-1.jpeg"
          alt="Yamaha 550"
        />
        <div class="info">
          <div>
            <p class="boat-title"><b>Yamaha YAM 550 100 HP</b></p>
            <p class="boat-engine">
              {{ $t("yamaha.one") }}<br />{{ $t("yamaha.two") }}<br /><br /><b
                >{{ $t("strings.engine") }}:</b
              >
              Honda 100 HP.<br /><b>{{ $t("strings.equipment") }}:</b>
              {{ $t("yamaha.three") }}<br />
              <b>{{ $t("strings.deposit") }}:</b> {{ $t("yamaha.four")
              }}<br /><b>CONSUMPTION:</b> 10L/H<br /><b
                >{{ $t("strings.max_speed") }}:</b
              >
              35KN<br /><b>{{ $t("strings.price") }}:</b><br />-
              {{ $t("yamaha.five") }}<br />- {{ $t("yamaha.six") }}<br /><b
                >{{ $t("strings.price_for") }}:</b
              ><br />- {{ $t("strings.skipper_price") }}<br /><br /><b
                >{{ $t("strings.license_req") }}!</b
              >
            </p>
            <br />
            <button @click="openLightbox('yamaha')" type="button">
              {{ $t("strings.view_yamaha_gallery") }}</button
            ><br />
          </div>
        </div>
      </div>
      <div class="boat">
        <div class="info reverse last">
          <div>
            <p class="boat-title"><b>Adria M-Sport 500 15 HP</b></p>
            <p class="boat-engine">
              {{ $t("adria.one") }}
              }}<br /><br /><b>{{ $t("strings.engine") }}:</b> Honda 15 HP.<br /><b
                >{{ $t("strings.equipment") }}:</b
              >
              {{ $t("adria.two") }}<br />
              <b>{{ $t("strings.deposit") }}:</b> {{ $t("adria.three")
              }}<br /><b>{{ $t("strings.consumption") }}:</b> 4L/H<br /><b
                >{{ $t("strings.max_speed") }}:</b
              >
              6KN<br /><b>{{ $t("strings.price") }}:</b><br />-
              {{ $t("adria.four") }}<br />- {{ $t("adria.five") }}<br /><b
                >{{ $t("strings.price_for") }}:</b
              ><br />- {{ $t("strings.skipper_price") }}<br /><br /><b
                >{{ $t("strings.license_req_not") }}!</b
              >
            </p>
            <br />
            <button @click="openLightbox('adria')" type="button">
              {{ $t("strings.view_adria_gallery") }}</button
            ><br />
          </div>
        </div>
        <img
          src="@/assets/images/boat/adria/adria-m-sport-500.jpeg"
          alt="Adria M-Sport 500"
        />
      </div>
      <div class="boat">
        <img src="@/assets/images/boat/lomac/lomac1.jpeg" alt="Lomac" />
        <div class="info last">
          <div>
            <p class="boat-title"><b>Lomac 660 150 HP</b></p>
            <p class="boat-engine">
              {{ $t("lomac.one") }}<br />{{ $t("lomac.two") }}<br /><br /><b
                >{{ $t("strings.engine") }}:</b
              >
              YAMAHA 150 HP.<br /><b>{{ $t("strings.equipment") }}:</b>
              {{ $t("lomac.three") }}<br />
              <b>{{ $t("strings.deposit") }}:</b> {{ $t("lomac.four") }}<br /><b
                >{{ $t("strings.consumption") }}:</b
              >
              14L/H<br /><b>{{ $t("strings.max_speed") }}:</b> 40KN<br /><b
                >{{ $t("strings.price") }}:</b
              ><br />- {{ $t("lomac.five") }}<br />- {{ $t("lomac.six")
              }}<br /><b>{{ $t("strings.price_for") }}:</b><br />-
              {{ $t("strings.skipper_price") }}<br /><br /><b
                >{{ $t("strings.license_req") }}!</b
              >
            </p>
            <br />
            <button @click="openLightbox('lomac')" type="button">
              {{ $t("strings.view_lomac_gallery") }}</button
            ><br />
          </div>
        </div>
      </div>
    </div>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="imgs"
      :index="i"
      @hide="visible = false"
    ></vue-easy-lightbox>
  </div>
</template>
<script setup>
import { ref } from "vue";
import VueEasyLightbox from "vue-easy-lightbox";
let imgs = ref([]);
let visible = ref(false);
let i = ref(0);

const openLightbox = (boat) => {
  imgs.value = [];
  let images = [
    "boat/yamaha/yam-550-1.jpeg",
    "boat/yamaha/yam-550-2.jpeg",
    "boat/yamaha/yam-550-3.jpeg",
    "boat/yamaha/yam-550-4.jpeg",
    "boat/yamaha/yam-550-5.jpeg",
    "boat/yamaha/yam-550-6.jpeg",
    "boat/yamaha/yam-550-7.jpeg",
    "boat/yamaha/yam-550-8.jpeg",
    "boat/yamaha/yam-550-9.jpeg",
    "boat/yamaha/yam-550-10.jpeg",
    "boat/yamaha/yam-550-11.jpeg",
    "boat/yamaha/yam-550-12.jpeg",
    "boat/yamaha/yam-550-13.jpeg",
  ];
  if (boat === "adria") {
    images = [
      "boat/adria/adria-m-sport-500.jpeg",
      "boat/adria/adria-m-sport-500-1.jpeg",
      "boat/adria/adria-m-sport-500-2.jpeg",
      "boat/adria/adria-m-sport-500-3.jpeg",
      "boat/adria/adria-m-sport-500-4.jpeg",
      "boat/adria/adria-m-sport-500-5.jpeg",
    ];
  }

  if (boat === "lomac") {
    images = [
      "boat/lomac/lomac1.jpeg",
      "boat/lomac/lomac2.jpeg",
      "boat/lomac/lomac3.jpeg",
      "boat/lomac/lomac4.jpeg",
      "boat/lomac/lomac5.jpeg",
      "boat/lomac/lomac6.jpeg",
      "boat/lomac/lomac7.jpeg",
      "boat/lomac/lomac8.jpeg",
      "boat/lomac/lomac9.jpeg",
      "boat/lomac/lomac10.jpeg",
      "boat/lomac/lomac11.jpeg",
    ];
  }

  for (let path of images) {
    imgs.value.push(require(`@/assets/images/${path}`));
  }

  visible.value = true;
};
</script>
<style scoped lang="scss">
@import "@/assets/css/partials/_home";
</style>
