<template>
  <div class="nav-wrapper">
    <router-link to="/">
      <img class="logo" src="@/assets/images/nautilus.webp" alt="Nautilus Rent a Boat and Excursions Biograd na Moru">
    </router-link>
    <nav class="navigation">
      <router-link to="/excursions" v-t="'navbar.excursions'"></router-link>
      <router-link to="/rentals" v-t="'navbar.rentals'"></router-link>
      <a href="#" @click.prevent="$i18n.locale = 'hr'" v-if="$i18n.locale === 'en'">
        <img src="@/assets/images/lang/hr.svg" alt="Hrvatski">
      </a>
      <a href="#" @click.prevent="$i18n.locale = 'en'" v-if="$i18n.locale === 'hr'">
        <img src="@/assets/images/lang/gb.svg" alt="English">
      </a>
    </nav>
  </div>
  
</template>
<script setup>
</script>
<style scoped lang="scss">
@import "@/assets/css/partials/_nav"
</style>
