<template>
  <footer>
    <p>NAUTILUS</p>
    <p class="address">23210, Biograd na Moru, Croatia</p>
    <a href="tel:+385981680632">TEL: +385 98 168 0632</a>
    <a href="tel:+385997398759">TEL: +385 99 739 8759</a>
    <span>@2023 Nautilus</span>
  </footer>
</template>
<script setup>
</script>
<style scoped lang="scss">
@import "@/assets/css/partials/_footer"
</style>
