import { defineStore } from 'pinia'
const storageKey = 'demoState'
const storedState = JSON.parse(localStorage.getItem(storageKey))

export const useLoginModule = defineStore('loginModule', {
    state: () => ({ 
        loginState : {
            loggedIn: storedState ? storedState.loggedIn : false,
            storedDate: storedState ? storedState.storedDate : null
        }
    }),
    actions: {
        isLoggedIn() {
            if (!this.loginState.loggedIn) {
                return false
            }

            let now = new Date().getTime()
            let notOlderThan = new Date(now - (120 * 60000)) // 120min cache
            return notOlderThan < this.loginState.storedDate
        },
        clearJWTData() {
            this.loginState.loggedIn = false
            this.loginState.storedDate = null
            localStorage.removeItem(storageKey)
        },
        setJWTData() {
            this.loginState.loggedIn = true
            this.loginState.storedDate = new Date().getTime()
            localStorage.setItem(storageKey, JSON.stringify(this.loginState))
        },
        login(credentials) {
            this.clearJWTData()
            if(btoa(credentials) === process.env.VUE_APP_OBFUSCATED) {
                this.setJWTData()
                return Promise.resolve(true)
            } else {
                return Promise.reject(false)
            }
        },
        logout() {
            this.clearJWTData()
            return Promise.resolve(true)
        }
    },
});