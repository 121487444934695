<template>
  <div class="home">
    <h1>Nautilus<br />Rent a boat Biograd na Moru</h1>
    <img
      src="@/assets/images/biograd-2.jpg"
      alt="Biograd na Moru"
      class="bg-banner"
    />
    <p>{{ $t("strings.take_one_of") }}</p>
    <div class="boats">
      <div class="boat">
        <img
          src="@/assets/images/boat/yamaha/yam-550-1.jpeg"
          alt="Yamaha 550"
        />
        <div class="info">
          <div>
            <p class="boat-title">Yamaha YAM 550 Rent</p>
            <p class="boat-engine">HONDA ENGINE 100HP</p>
            <router-link to="/rentals" v-t="'navbar.rent'"></router-link>
          </div>
        </div>
      </div>
      <div class="boat r">
        <div class="info reverse">
          <div>
            <p class="boat-title">Adria M-Sport 500 Rent</p>
            <p class="boat-engine">HONDA ENGINE 15HP</p>
            <router-link to="/rentals" v-t="'navbar.rent'"></router-link>
          </div>
        </div>
        <img
          src="@/assets/images/boat/adria/adria-m-sport-500.jpeg"
          alt="Adria M-Sport 500"
        />
      </div>
      <div class="boat">
        <img src="@/assets/images/boat/lomac/lomac1.jpeg" alt="Lomac" />
        <div class="info last">
          <div>
            <p class="boat-title">Lomac Excursions</p>
            <p class="boat-engine">YAMAHA ENGINE 150HP</p>
            <router-link
              to="/excursions"
              v-t="'navbar.excursions'"
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import "@/assets/css/partials/_home";
</style>
