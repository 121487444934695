import { createRouter, createWebHistory } from 'vue-router'
import {useLoginModule} from '@/modules/Login'
import HomeView from '@/views/HomeView.vue'
import ExcursionsView from '@/views/ExcursionsView.vue'
import RentalsView from '@/views/RentalsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home | Nautilus Rent A Boat & Excursions'
    }
  },
  {
    path: '/excursions',
    name: 'excursions',
    component: ExcursionsView,
    meta: {
      title: 'Excursions | Nautilus Rent A Boat & Excursions'
    }
  },
  {
    path: '/rentals',
    name: 'rentals',
    component: RentalsView,
    meta: {
      title: 'Rent a Boat Biograd na Moru | Nautilus Rent A Boat & Excursions'
    }
  },
  { 
    path: '/:catchAll(.*)', 
    name: 'not-found',
    meta: {
      title: '404 | Nautilus Rent A Boat & Excursions'
    },
    component: () => import('@/views/NotFound.vue')
  }
]

const router = new createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
      if (to.hash) {
        return document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' });
      } else {
        return savedPosition || { x: 0, y: 0 }
      }
    }
})

router.beforeEach((to, from, next) => {
  const loginStore = useLoginModule()

  if(to.meta.title) {
     document.title = to.meta.title
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (loginStore.isLoggedIn()) {
      next()
      return
    } else {
      next('/')
    }
  } else {
    if (to.path == '/' && loginStore.isLoggedIn()) {
      if (to.query.action === 'logout'){
        loginStore.logout()
        .then(() => {
            next('/')
        })
      }
      next('/dashboard')
      return
    }
    next()
  }
})

export default router
