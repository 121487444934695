<template>
  <div class="home">
    <h1>Excursions</h1>
    <p>{{$t('strings.excursions_title')}}</p>
    <p class="smaller">{{$t('strings.excursions_subtitle')}}</p>
    <div class="boats excursions-rentals">
      <div class="boat">
        <img src="@/assets/images/boat/dupini/dupini-1.jpeg" alt="Dolphin excursions">
        <div class="info">
          <div>
            <p class="boat-title"><b>Dolphin Tours</b></p>
            <p class="boat-engine" v-html="$t('dolphins')"></p><br>
            <button @click="openLightbox('dolphins')" type="button">{{$t('strings.view_dolphins_gallery')}}</button><br>
          </div>
        </div>
      </div>
      <div class="boat r">
        <div class="info reverse">
          <div>
            <p class="boat-title"><b>Kornati Islands Tours</b></p>
            <p class="boat-engine" v-html="$t('kornati')"></p><br>
            <button @click="openLightbox('kornati')" type="button">{{$t('strings.view_kornati_gallery')}}</button><br>
          </div>
        </div>
        <img src="@/assets/images/boat/kornati/kornati-1.jpeg" alt="Kornati excursions">
      </div>
      <div class="boat">
        <img src="@/assets/images/boat/vrgada/vrgada-4.jpeg" alt="Vrgada excursions">
        <div class="info">
          <div>
            <p class="boat-title"><b>Vrgada Island Tours</b></p>
            <p class="boat-engine" v-html="$t('vrgada')"></p><br>
            <button @click="openLightbox('vrgada')" type="button">{{$t('strings.view_vrgada_gallery')}}</button><br>
          </div>
        </div>
      </div>
      <div class="boat r">
        <div class="info reverse last">
          <div>
            <p class="boat-title"><b>Galešnjak Love Island Sunset Tours</b></p>
            <p class="boat-engine" v-html="$t('sunset')"></p><br>
            <button @click="openLightbox('sunset')" type="button">{{$t('strings.view_sunset_gallery')}}</button><br>
          </div>
        </div>
        <img src="@/assets/images/boat/sunset/sunset-1.jpeg" alt="Sunset excursions">
      </div>
    </div>
    <vue-easy-lightbox :visible="visible" :imgs="imgs" :index="i" @hide="visible = false"></vue-easy-lightbox>
  </div>
</template>
<script setup>
import {ref} from 'vue'
import VueEasyLightbox from 'vue-easy-lightbox'
let imgs = ref([])
let visible = ref(false)
let i = ref(0)

const openLightbox = (excursion) => {
  imgs.value = []
  let images = ['boat/kornati/kornati-1.jpeg', 'boat/kornati/kornati-2.jpeg', 'boat/kornati/kornati-3.jpeg', 'boat/kornati/kornati-4.jpeg', 'boat/kornati/kornati-5.jpeg', 'boat/kornati/kornati-6.jpeg']

  if(excursion === 'vrgada') {
    images = ['boat/vrgada/vrgada-1.jpeg', 'boat/vrgada/vrgada-2.jpeg', 'boat/vrgada/vrgada-3.jpeg', 'boat/vrgada/vrgada-4.jpeg']
  }

  if(excursion === 'sunset') {
    images = ['boat/sunset/sunset-1.jpeg', 'boat/sunset/sunset-2.jpeg']
  }

  if(excursion === 'dolphins') {
    images = ['boat/dupini/dupini-1.jpeg', 'boat/dupini/dupini-2.jpeg']
  }
  
  for (let path of images) {
    imgs.value.push(require(`@/assets/images/${path}`))
  }
    
  visible.value = true
}
</script>
<style scoped lang="scss">
@import "@/assets/css/partials/_home"
</style>