<template>
  <div class="page-content">
    <Navigation />
    <router-view />
    <div class="contact-form">
      <h2>{{$t('strings.contact_us')}}</h2>
      <div class="form-group">
        <label for="name">{{$t('strings.name')}} *</label>
        <input type="text" v-model="mail.name">
      </div>
      <div class="form-group">
        <label for="email">Email *</label>
        <input type="email" v-model="mail.email">
      </div>
      <div class="form-group">
        <label for="phone">{{$t('strings.phone_number')}} *</label>
        <input type="text" v-model="mail.phone">
      </div>
      <div class="form-group">
        <label for="message">{{$t('strings.message')}} *</label>
        <textarea v-model="mail.message"></textarea>
      </div>
      <div class="form-group" v-if="mailSuccess">
        <p class="smaller success">{{$t('strings.mail_success')}}</p>
      </div>
      <div class="form-group" v-if="error">
        <p class="smaller error">{{$t('strings.please_enter_valid_data')}}</p>
      </div>
      <div class="form-group" v-if="mailError">
        <p class="smaller error">{{$t('strings.error_sending_mail')}}</p>
      </div>
      <div class="form-group">
        <button @click="sendMessage()">{{$t('strings.send_message')}}</button>
      </div>
    </div>
    <GoogleMap api-key="AIzaSyDJdq6MUcFQhjODdsDQiAqU9XGEAWtRpaA" style="width: 1200px; max-width: 90%; margin: auto; height: 600px; border-radius: 16px;overflow: hidden;" :center="center" :zoom="15">
      <Marker :options="{ position: center }" />
    </GoogleMap>
    <Footer />
  </div>
</template>
<script setup>
import axios from 'axios'
import {ref} from 'vue'
import Navigation from '@/components/NavigationComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import { GoogleMap, Marker } from 'vue3-google-map';
let center = ref({ lat: 43.936163, lng: 15.442232 })
let mail = ref({
  name: '',
  email: '',
  phone: '',
  message: ''
})
let error = ref(false)
let mailError = ref(false)
let mailSuccess = ref(false)

const sendMessage = () => {
  error.value = false
  mailError.value = false
  mailSuccess.value = false
  if(mail.value.name.trim().length === 0) {
    error.value = true
    return
  }

  if(mail.value.phone.trim().length === 0) {
    error.value = true
    return
  }

  if(mail.value.email.trim().length === 0 || !validEmail()) {
    error.value = true
    return
  }

  if(mail.value.message.trim().length === 0) {
    error.value = true
    return
  }

  axios.post('/mail.php', {
    'name': mail.value.name,
    'phone': mail.value.phone,
    'email': mail.value.email,
    'message': mail.value.message
  }).then((response) => {
    if(response.data === 'sent') {
      mailSuccess.value = true
      mail.value = {
        name: '',
        email: '',
        phone: '',
        message: ''
      } 
    } else {
      mailError.value = true
    }
  }).catch(()=> {
    alert('Error sending mail. Please reach out to us on info@nautilus-rent-a-boat-biograd.com')
    mailError.value = true
  })
}

const validEmail = () => {
  const re = /\S+@\S+\.\S+/;
  return re.test(mail.value.email)
}
</script>
<style lang="scss">
  @import "@/assets/css/app.scss"
</style>

